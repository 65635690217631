<template>
  <div class="animated fadeIn">
    <b-card
      header-tag="header"
      footer-tag="footer">
      <div slot="header">
        <strong> Filtros </strong>
        <b-button variant="primary" class="text-white float-right" size="xs" @click="filtrosAvancados()">Avançado</b-button>
      </div>
      <div class="p-1 mt-2">
        <b-row class="mb-1">
          <b-col sm="12" md="4" class="d-md-block">
            <b-button-toolbar class="float-left">
              <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="changePeriodo" name="radiosBtn">
                <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
              </b-form-radio-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <multiselect v-model="filtro.fields.gestao" :options="filtro.listas.gestao" placeholder="Todas as Gestões" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.gestao.length" @mousedown.prevent.stop="clearAll('gestao')"></div>
                </template>
              </multiselect>
            </b-col>
            <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <multiselect v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao" placeholder="Todas as Lojas" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.centres.length" @mousedown.prevent.stop="clearAll('centres')"></div>
                </template>
              </multiselect>
            </b-col>
            <b-col sm="4">
              <multiselect v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda" placeholder="Todas as Formas de Venda" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.formaVenda.length" @mousedown.prevent.stop="clearAll('formaVenda')"></div>
                </template>
              </multiselect>
            </b-col>
          <!-- <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao" @change="filterLojas"></b-form-select>
          </b-col>
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centres"></b-form-select>
          </b-col>
          <b-col sm="4">
            <b-form-select :plain="true" v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda"></b-form-select>
          </b-col> -->
        </b-row>
        <b-row class="mb-1">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1">
            <b-form-input :plain="true" v-model="filtro.fields.dataIni" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3">
            <b-form-input :plain="true" v-model="filtro.fields.dataFim" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col sm="1" v-if="!filtro.filtrosAvancados">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-1" v-if="filtro.filtrosAvancados">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3">
            <multiselect v-model="filtro.fields.vendedores" :options="filtro.listas.vendedores" placeholder="Todos os Vendedores" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
              </template>
              <template slot="clear">
                <div class="multiselect__clear" v-if="filtro.fields.vendedores.length" @mousedown.prevent.stop="clearAll('vendedores')"></div>
              </template>
            </multiselect>
          </b-col>
          <b-col sm="12" md="12" class="mt-2 mb-2">
            <div class="custom-control custom-checkbox pt-1">
              <input type="checkbox" class="custom-control-input" id="chkDesconsiderarPecas" v-model="filtro.fields.desconsiderarPecas">
              <label class="custom-control-label" for="chkDesconsiderarPecas">Desconsiderar devoluções em "Peças"</label>
            </div>
          </b-col>
          <b-col sm="12" md="10" lg="11" class="mt-2 mb-2">
            <div class="custom-control custom-checkbox pt-1">
              <input type="checkbox" class="custom-control-input" id="chkAtendimentoPorCliente" v-model="filtro.fields.atendimentoPorCliente">
              <label class="custom-control-label" for="chkAtendimentoPorCliente">Considerar atendimento único as vendas para o mesmo cliente</label>
            </div>
          </b-col>
          <b-col sm="1" md="1">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ totalRecords | number }}</div>
            <div class="h5">Clientes Atendidos</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ getPercentualTotal() }}%</div>
            <div class="h5">Percentual do Total</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix text-center text-white p-3" body-bg-variant="primary">
            <div class="h2 mb-1 font-weight-bold">{{ totalClientesGrupo | number }}</div>
            <div class="h5">Total de Clientes</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-for="(item, index) in items" :key="index">
      <b-col cols="12" sm="12" lg="12">
        <b-card header-tag="header">
          <div slot="header" class="float-left ">
            <strong>{{ item.name }}</strong>
          </div>
          <div slot="header" class="float-right" v-if="exportable">
            <download-excel
              :name="`${item.name || 'Clientes'}.xls`"
              :fields="exportFields"
              :data="item.records">
                <i class="fa fa-download cursor-pointer" alt="Exportar" title="Exportar"></i>
            </download-excel>
          </div>
          <b-table :items="item.records" :fields="fields" striped small responsive="md" :per-page="perPage" :current-page="currentPage">
            <template slot="codigo" slot-scope="data">
              <b-link @click="infoCliente(data.item.codigo, data.item.digito)">{{ data.item.codigo }}.{{ data.item.digito }}</b-link>
            </template>
            <template slot="vendas" slot-scope="data">
              {{ data.item.vendas | currency({ symbol: '' }) }}
            </template>
            <template slot="pecas" slot-scope="data">
              {{ data.item.pecas | number }}
            </template>
            <template slot="atendimentos" slot-scope="data">
              {{ data.item.atendimentos | number }}
            </template>
          </b-table>
          <nav>
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" prev-text="‹‹" next-text="››" hide-goto-end-buttons class="mb-1" />
          </nav>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="modal-info-cliente" centered size="lg" header-bg-variant="primary" title="Cliente">
      <template slot="modal-header" >
        <h5 class="mx-auto">
          <span class="text-white">{{ clienteInfo.cliente }}</span>
        </h5>
      </template>

      <template slot="default">
        <b-row class="mb-1">
          <b-col sm="6">
            <span class="text-primary mr-1">Cliente desde:</span>
            <span class="text-black">{{ clienteInfo.desde }}</span>
          </b-col>
          <b-col sm="6">
            <span class="text-primary mr-1">Atendimentos:</span>
            <span class="text-black">{{ clienteInfo.atendimentos | number }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="6">
            <span class="text-primary mr-1">Última compra:</span>
            <span class="text-black">{{ clienteInfo.ultima_compra | currency }}</span>
          </b-col>
          <b-col sm="6">
            <span class="text-primary mr-1">Maior compra:</span>
            <span class="text-black">{{ clienteInfo.maior_compra | currency }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="12">
            <span class="text-primary mr-1">Último vendedor que atendeu:</span>
            <span class="text-black">{{ clienteInfo.ultimo_vendedor }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mb-1">
          <b-col :md="6" :lg="(12 / clienteInfo.contatos.length)" v-for="(contato, index) in clienteInfo.contatos" :key="index">
            <b-link :href="contato.link" target="new">
              <i class="fa" :class="contato.icon"></i> {{ contato.formated || contato.value }}
            </b-link>
          </b-col>
        </b-row>
      </template>

      <template slot="modal-footer" slot-scope="{ ok, cancel }">
        <!-- <b-button size="sm" variant="outline-primary" @click="ok()">
          Ver Cadastro Completo
        </b-button> -->
        <b-button size="sm" variant="outline-primary" @click="cancel()">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import Multiselect from 'vue-multiselect'
import session from '@/shared/session';
import lojasPorGestaoMultiMixin from '@/core/mixins/lojasPorGestaoMulti.mixin.js';

export default {
  name: 'ClientesAtendidos',
  components: {
    cTable,
    Multiselect
  },
  mixins:[
    lojasPorGestaoMultiMixin
  ],
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get('gestoes').filter(c => c.value !== 0),
          centres: session.get('lojas').filter(c => c.value !== 0),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas').filter(c => c.value !== 0),
          vendedores: session.get('vendedores'),
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: 'Hoje',
          gestao: [],
          centres: [],
          formaVenda: [],
          vendedores: [],
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          limit: null,
          offset: 0,
          desconsiderarPecas: false,
          atendimentoPorCliente: false
        },
        filtrosAvancados: false
      },
      items: [],
      fields: [
        {key: 'codigo', label: 'Cliente', sortable: true},
        {key: 'nome', label: 'Nome Completo', sortable: true},
        {key: 'vendas', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'pecas', label: 'Peças', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'atendimentos', label: 'Atendimentos', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'}
      ],
      clienteInfo: {},
      exportable: true,
      totalRecords: 0,
      currentPage: 1,
      perPage: 50
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          const clientesAPI = new API('api/clientes-atendidos');
          const {data} = await clientesAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = data.data;
          this.totalRecords = data.total;
        }
      } catch (e) {
        console.error(e)
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    dataChanged() {
      this.filtro.fields.periodo = 'Periodo';
    },
    changePeriodo(periodo) {
      switch(periodo) {
        case 'Hoje': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Ontem': {
          this.filtro.fields.dataIni = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().subtract(1, 'day').format('YYYY-MM-DD');
          break;
        }
        case 'Semana': {
          this.filtro.fields.dataIni = moment().startOf('week').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('week').format('YYYY-MM-DD');
          break;
        }
        case 'Mes': {
          this.filtro.fields.dataIni = moment().startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('month').format('YYYY-MM-DD');
          break;
        }
        default: return;
      }
      this.search();
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        centres,
        formaVenda,
        vendedores,
        limit,
        offset
      } = filters;

      filter.centres      = centres.length === 0 ? [] : centres;
      filter.formaVenda   = formaVenda.length === 0 ? [] : formaVenda;

      filter.vendedores   = vendedores.length === 0 ? [] : vendedores;

      filter.centres      = (filter.centres && filter.centres.map(c => c.value).join(',')) || '0';
      filter.formaVenda   = (filter.formaVenda && filter.formaVenda.map(c => c.value).join(',')) || '0';

      filter.vendedores   = (filter.vendedores && filter.vendedores.map(c => c.value).join(',')) || '0';

      filter.limit = Number(limit);
      filter.offset = Number(offset);
      return filter;
    },
    async infoCliente(codigo, digito) {
      try {
        const infoClienteAPI = new API('api/info-cliente');
        const {data} = await infoClienteAPI.get({ codigo, digito });
        this.clienteInfo = data.data;
        this.clienteInfo['contatos'] = [];
        if (this.clienteInfo.telefones.length > 0) {
          const { telefones, whatsapps } = this.clienteInfo;
          for (const i in telefones) {
            if (telefones[i]) {
              const icon = whatsapps[i] === 'S' ? 'fa-whatsapp' : 'fa-phone'
              this.clienteInfo.contatos.push({
                icon,
                type: 'telefone',
                value: `${telefones[i].replace(/[^\d]/g,'')}`,
                formated: this.phoneFormatter(telefones[i]),
                link: icon === 'fa-whatsapp' ?
                  `https://web.whatsapp.com/send?phone=55${telefones[i]}` :
                  `callto:${telefones[i]}`
              })
            }
          }
        }
        if (this.clienteInfo.email) {
          this.clienteInfo.contatos.push({
            icon: 'fa-envelope',
            type: 'email',
            value: this.clienteInfo.email,
            link: `mailto:${this.clienteInfo.email}`
          })
        }
        this.$bvModal.show('modal-info-cliente');
      } catch (e) {
        console.error(e)
      }
    },
    getPercentualTotal() {
      const divisor = this.totalClientesGrupo > 0 ? this.totalClientesGrupo : 1
      return (((this.totalRecords * 100) / divisor) || 0).toFixed(2).replace('.', ',')
    },
    phoneFormatter(phone) {
      let _phone = '';
      if (phone) {
        _phone = phone.replace(/[^\d]/g,'');
        _phone = phone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/,'($1) $2-$3');
      }
      return _phone
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    },
    filtrosAvancados() {
      if (this.filtro.filtrosAvancados) {
        this.filtro.fields.grupos = [];
        this.filtro.fields.subgrupos = [];
        this.filtro.fields.fornecedores = [];
        this.filtro.fields.vendedores = [];
      }

      setTimeout(() => {this.filtro.filtrosAvancados = !this.filtro.filtrosAvancados},200);
    },
    clearAll(field) {
      if (this.filtro.fields[field] && this.filtro.fields[field].length > 0) {
        this.filtro.fields[field] = [];
      }
    }
  },
  created() {
    this.search();
  },
  computed: {
    totalClientesGrupo: function() {
      return session.get('total-clientes');
    },
    totalRows: function() {
      return this.totalRecords
    },
    exportFields: function() {
      const obj = {};
      return this.fields.reduce((item, key) => {
        obj[key['label']] = key['key'];
        return obj
      }, {});
    }
  }
}
</script>
