var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("strong", [_vm._v(" Filtros ")]),
              _c(
                "b-button",
                {
                  staticClass: "text-white float-right",
                  attrs: { variant: "primary", size: "xs" },
                  on: {
                    click: function($event) {
                      return _vm.filtrosAvancados()
                    }
                  }
                },
                [_vm._v("Avançado")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "p-1 mt-2" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-md-block", attrs: { sm: "12", md: "4" } },
                    [
                      _c(
                        "b-button-toolbar",
                        { staticClass: "float-left" },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                buttons: "",
                                "button-variant": "outline-primary",
                                name: "radiosBtn"
                              },
                              on: { change: _vm.changePeriodo },
                              model: {
                                value: _vm.filtro.fields.periodo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "periodo", $$v)
                                },
                                expression: "filtro.fields.periodo"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Hoje" }
                                },
                                [_vm._v("Hoje")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Ontem" }
                                },
                                [_vm._v("Ontem")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Semana" }
                                },
                                [_vm._v("Semana")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Mes" }
                                },
                                [_vm._v("Mês")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Periodo" }
                                },
                                [_vm._v("Período")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.filtro.listas.gestao,
                            placeholder: "Todas as Gestões",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.gestao,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "gestao", $$v)
                            },
                            expression: "filtro.fields.gestao"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.gestao.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("gestao")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options:
                              _vm.filtro.listas.centresFiltradosPorGestao,
                            placeholder: "Todas as Lojas",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.centres,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "centres", $$v)
                            },
                            expression: "filtro.fields.centres"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.centres.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("centres")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "4" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.filtro.listas.formaVenda,
                            placeholder: "Todas as Formas de Venda",
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": true,
                            "show-labels": false,
                            label: "text",
                            "track-by": "value"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var values = ref.values
                                var search = ref.search
                                var isOpen = ref.isOpen
                                return [
                                  values.length == 1 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opção selecionada"
                                        )
                                      ])
                                    : _vm._e(),
                                  values.length >= 2 && !isOpen
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(values.length) +
                                            " opções selecionadas"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.filtro.fields.formaVenda,
                            callback: function($$v) {
                              _vm.$set(_vm.filtro.fields, "formaVenda", $$v)
                            },
                            expression: "filtro.fields.formaVenda"
                          }
                        },
                        [
                          _c("template", { slot: "clear" }, [
                            _vm.filtro.fields.formaVenda.length
                              ? _c("div", {
                                  staticClass: "multiselect__clear",
                                  on: {
                                    mousedown: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.clearAll("formaVenda")
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pr-1",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataIni,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                          },
                          expression: "filtro.fields.dataIni"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pl-1 pl-md-3",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataFim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                          },
                          expression: "filtro.fields.dataFim"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.filtro.filtrosAvancados
                    ? _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.filtro.filtrosAvancados
                ? _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 pl-1 pl-md-3",
                          attrs: { cols: "6", sm: "6", md: "4" }
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                options: _vm.filtro.listas.vendedores,
                                placeholder: "Todos os Vendedores",
                                multiple: true,
                                "close-on-select": false,
                                "clear-on-select": true,
                                "show-labels": false,
                                label: "text",
                                "track-by": "value"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var values = ref.values
                                      var search = ref.search
                                      var isOpen = ref.isOpen
                                      return [
                                        values.length == 1 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opção selecionada"
                                              )
                                            ])
                                          : _vm._e(),
                                        values.length >= 2 && !isOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(values.length) +
                                                  " opções selecionadas"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1659997751
                              ),
                              model: {
                                value: _vm.filtro.fields.vendedores,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "vendedores", $$v)
                                },
                                expression: "filtro.fields.vendedores"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }, [
                                _vm.filtro.fields.vendedores.length
                                  ? _c("div", {
                                      staticClass: "multiselect__clear",
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearAll("vendedores")
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-2 mb-2",
                          attrs: { sm: "12", md: "12" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "custom-control custom-checkbox pt-1"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filtro.fields.desconsiderarPecas,
                                    expression:
                                      "filtro.fields.desconsiderarPecas"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "chkDesconsiderarPecas"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.filtro.fields.desconsiderarPecas
                                  )
                                    ? _vm._i(
                                        _vm.filtro.fields.desconsiderarPecas,
                                        null
                                      ) > -1
                                    : _vm.filtro.fields.desconsiderarPecas
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.filtro.fields.desconsiderarPecas,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "desconsiderarPecas",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "desconsiderarPecas",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "desconsiderarPecas",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "chkDesconsiderarPecas" }
                                },
                                [_vm._v('Desconsiderar devoluções em "Peças"')]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-2 mb-2",
                          attrs: { sm: "12", md: "10", lg: "11" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "custom-control custom-checkbox pt-1"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.filtro.fields.atendimentoPorCliente,
                                    expression:
                                      "filtro.fields.atendimentoPorCliente"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "chkAtendimentoPorCliente"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.filtro.fields.atendimentoPorCliente
                                  )
                                    ? _vm._i(
                                        _vm.filtro.fields.atendimentoPorCliente,
                                        null
                                      ) > -1
                                    : _vm.filtro.fields.atendimentoPorCliente
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.filtro.fields.atendimentoPorCliente,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "atendimentoPorCliente",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.filtro.fields,
                                            "atendimentoPorCliente",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "atendimentoPorCliente",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "chkAtendimentoPorCliente" }
                                },
                                [
                                  _vm._v(
                                    "Considerar atendimento único as vendas para o mesmo cliente"
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "1", md: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm._f("number")(_vm.totalRecords)))
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Clientes Atendidos")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.getPercentualTotal()) + "%")
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Percentual do Total")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "12", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c(
                    "b-card-body",
                    {
                      staticClass: "clearfix text-center text-white p-3",
                      attrs: { "body-bg-variant": "primary" }
                    },
                    [
                      _c("div", { staticClass: "h2 mb-1 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm._f("number")(_vm.totalClientesGrupo)))
                      ]),
                      _c("div", { staticClass: "h5" }, [
                        _vm._v("Total de Clientes")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "b-row",
          { key: index },
          [
            _c(
              "b-col",
              { attrs: { cols: "12", sm: "12", lg: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "float-left ",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("strong", [_vm._v(_vm._s(item.name))])]
                    ),
                    _vm.exportable
                      ? _c(
                          "div",
                          {
                            staticClass: "float-right",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [
                            _c(
                              "download-excel",
                              {
                                attrs: {
                                  name: (item.name || "Clientes") + ".xls",
                                  fields: _vm.exportFields,
                                  data: item.records
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-download cursor-pointer",
                                  attrs: { alt: "Exportar", title: "Exportar" }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-table", {
                      attrs: {
                        items: item.records,
                        fields: _vm.fields,
                        striped: "",
                        small: "",
                        responsive: "md",
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "codigo",
                            fn: function(data) {
                              return [
                                _c(
                                  "b-link",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.infoCliente(
                                          data.item.codigo,
                                          data.item.digito
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(data.item.codigo) +
                                        "." +
                                        _vm._s(data.item.digito)
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "vendas",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("currency")(data.item.vendas, {
                                        symbol: ""
                                      })
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: "pecas",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm._f("number")(data.item.pecas)) +
                                    "\n          "
                                )
                              ]
                            }
                          },
                          {
                            key: "atendimentos",
                            fn: function(data) {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("number")(data.item.atendimentos)
                                    ) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "nav",
                      [
                        _c("b-pagination", {
                          staticClass: "mb-1",
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                            "prev-text": "‹‹",
                            "next-text": "››",
                            "hide-goto-end-buttons": ""
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-info-cliente",
            centered: "",
            size: "lg",
            "header-bg-variant": "primary",
            title: "Cliente"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-primary" },
                      on: {
                        click: function($event) {
                          return cancel()
                        }
                      }
                    },
                    [_vm._v("\n        Fechar\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "modal-header" }, [
            _c("h5", { staticClass: "mx-auto" }, [
              _c("span", { staticClass: "text-white" }, [
                _vm._v(_vm._s(_vm.clienteInfo.cliente))
              ])
            ])
          ]),
          _c(
            "template",
            { slot: "default" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c("b-col", { attrs: { sm: "6" } }, [
                    _c("span", { staticClass: "text-primary mr-1" }, [
                      _vm._v("Cliente desde:")
                    ]),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(_vm._s(_vm.clienteInfo.desde))
                    ])
                  ]),
                  _c("b-col", { attrs: { sm: "6" } }, [
                    _c("span", { staticClass: "text-primary mr-1" }, [
                      _vm._v("Atendimentos:")
                    ]),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(
                        _vm._s(_vm._f("number")(_vm.clienteInfo.atendimentos))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c("b-col", { attrs: { sm: "6" } }, [
                    _c("span", { staticClass: "text-primary mr-1" }, [
                      _vm._v("Última compra:")
                    ]),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.clienteInfo.ultima_compra)
                        )
                      )
                    ])
                  ]),
                  _c("b-col", { attrs: { sm: "6" } }, [
                    _c("span", { staticClass: "text-primary mr-1" }, [
                      _vm._v("Maior compra:")
                    ]),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.clienteInfo.maior_compra))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c("span", { staticClass: "text-primary mr-1" }, [
                      _vm._v("Último vendedor que atendeu:")
                    ]),
                    _c("span", { staticClass: "text-black" }, [
                      _vm._v(_vm._s(_vm.clienteInfo.ultimo_vendedor))
                    ])
                  ])
                ],
                1
              ),
              _c("hr"),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                _vm._l(_vm.clienteInfo.contatos, function(contato, index) {
                  return _c(
                    "b-col",
                    {
                      key: index,
                      attrs: { md: 6, lg: 12 / _vm.clienteInfo.contatos.length }
                    },
                    [
                      _c(
                        "b-link",
                        { attrs: { href: contato.link, target: "new" } },
                        [
                          _c("i", { staticClass: "fa", class: contato.icon }),
                          _vm._v(
                            " " +
                              _vm._s(contato.formated || contato.value) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }